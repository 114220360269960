.payment-container {
  .hide-on-mobile {
    display: none;
  }

  .event-payout-container {
    margin-top: 30px;

    .full-info {
      display: none;
    }

    .limited-info {
      display: block;
    }

    .payout-table {
      table {
        border-collapse: unset;
        border-radius: 3px;
        font-size: 12px;

        thead {
          th {
            border-radius: 3px;
            background-color: darkblue;
            color: white;
            vertical-align: top;
          }
        }

        tbody {
          tr {
            td {
              border-radius: 3px;
              
              &:not(:first-child) {
                text-align: right;
              }
              &:last-child {
                text-align: center;
              }
              i {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .payout-detail-container {
    .row {
      margin: 0;
    }
    .tickets-wrapper, .donations-wrapper {
      border: 2px solid #fff;
      padding: 20px;
      margin: 0 0 20px 0;

      h4 {
        text-align: center;
        text-decoration: underline;
      }

      .tickets-container, .revenue-container {
        border-bottom: 2px solid #000;
        padding: 10px;
      }

      .item {
        position: relative;
        display: flex;
        justify-content: space-between;

        .prepend {
          position: absolute;
          left: -15px;
        }

        .share-percent {
          color: red;
        }
      }

      .total-payout {
        padding: 10px;
        color: #16c79a;
      }
    }
  }
}

@media (min-width: 475px) {
  .payment-container {
    .event-payout-container {
      .title {
        display: inline;
        margin-right: 20px;
      }
    }
  }
}

@media(min-width: 576px) {
  .payment-container {
    .event-payout-container {
      .full-info {
        display: block;
      }

      .limited-info {
        display: none;
      }
    }
    .payout-detail-container {
      .tickets-wrapper, .donations-wrapper {
        margin: 0 20px;
      }
    }
  }
}

@media (min-width: 768px) {
  .payment-container {
    .hide-on-mobile {
      display: table-cell;
    }
  }
}
