.dashboard-container {
  max-width: 1200px;
  padding: 30px 10px 78px 10px;
  margin: auto;

  .dashboard-header {
    margin-bottom: 30px;
  }

  .dashboard-menu {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;

    .menu {
      display: flex;
      justify-content: center;
    }

    &.hide {
      display: none;
    }
  }

  .dashboard-pages {
    background: #e0e0e0;
    padding: 10px;
    border-radius: 10px;
  }
}

@media(min-width: 475px) {
  .dashboard-container {
    .dashboard-menu {
      grid-template-columns: auto auto auto;
      grid-gap: 15px;
    }
  }
}

@media(min-width: 576px) {
  
}

@media(min-width: 768px) {
  .dashboard-container {
    padding-bottom: 10px;
    display: grid;
    grid-template-columns: 180px auto;

    .dashboard-header {
      display: none;
    }
    .dashboard-menu {
      display: grid!important;
      grid-template-columns: 160px;
      grid-gap: 15px;
    }

    .dashboard-pages {
      display: block;
    }
  }
}

@media(min-width: 992px) {
  .dashboard-container {
    .dashboard-menu {

    }
  }
}

@media(min-width: 1047px) {
  .dashboard-container {
    .dashboard-menu {
    }
  }
}

@media(min-width: 1440px) {

}
