.header-wrapper {
  background-color: #ffffff;

  .card {
    min-height: 130px;

    .card-title {
      font-size: 16px;
      text-align: center;
    }
  }

  .header-info {
    text-align: center;

    .personal-info {
      margin-bottom: 20px;

      .creator-avatar {
        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          border: 1px solid #eaeaea;
          background-color: #ffffff;
          object-fit: cover;
        }
      }

      .creator-name {
        font-size: 16px;
      }
    }
    .payment-info {
      margin-bottom: 20px;

      .title {
        font-size: 12px;
      }

      .balance {
        font-size: 16px;
        font-weight: bold;
      }
    }

    .stripe-account {
      font-size: 13px;
      color: #219f72;
      cursor: pointer;
    }
  }
}

@media (min-width: 576px) {
  .header-wrapper {
    .header-info {
      display: grid;
      grid-template-columns: 2fr 2fr 2fr;
      margin-top: 20px;
      text-align: unset;

      .personal-info {
        display: grid;
        grid-template-columns: 45px auto;
        grid-gap: 6px;

        .creator-avatar {
          display: flex;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
          }
        }

        .creator-name {
          display: flex;
          align-items: center;
        }

        .stripe-account {
          grid-column: 1/span 2;
        }
      }

      .payment-info {
        text-align: right;
      }

      .payout-button {
        text-align: center;
        
        button {
          margin-bottom: 6px;
        }
      }
    }
  }
}
