.footer-wrapper {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 20px 0px;
  background-color: #ffffff;
  border-top: 1px solid #c5c5c5;

  .navbar {
    display: block;
    padding: 0;
    ul {
      display: grid;
      list-style: none;
      justify-self: end;
      grid-template-columns: repeat(5, 1fr);
    }

    .nav {
      .nav-item {
        min-width: 60px;

        .svg-inline--fa {
          font-size: 25px;
        }
      }
    }
  }

  &.bottom-bar-hidden {
    padding-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .footer-wrapper {
    display: none;
  }
}
