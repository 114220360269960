.mini-card-wrapper {
  .card {
    border: none;
    box-shadow: 0px 0px 5px 5px #dedcdc;
    min-width: 140px;
    height: 100px;
    border-radius: 6px;
    cursor: pointer;

    .card-subtitle {
      font-size: 14px;
      text-align: center;
      margin-top: 6px;
    }

    &:hover {
      box-shadow: 2px 2px 5px 5px #dedcdc;
    }
  }

  .icon-wrapper {
    width: 50px;
    height: 50px;
    margin: auto;

    svg {
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
