.nav-wrapper {
  width: 100%;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  -webkit-position: sticky;
  top: -16px;
  background-color: #fff;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  box-shadow: none;
  height: 86px;
}

.nav-wrapper::before,
.nav-wrapper::after {
  content: '';
  display: block;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  -webkit-position: sticky;
  height: 16px;
}

.nav-wrapper::before {
  top: 54px;
  box-shadow: 0px 5.5px 7px rgba(0, 0, 0, 0.2);
}

.nav-wrapper::after {
  background: linear-gradient(white 10%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0.4) 100%, transparent);
  top: 0px;
  /* cover should fall over shadow */
  z-index: 2;
}

.nav-wrapper >nav {
  height: 70px;
  position: sticky;
  -webkit-position: sticky;
  top: 0px;
  margin-top: -16px;
  z-index: 3;
}

/* NAVIGATION */

.navbar {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  overflow: hidden;
  padding: 0.2rem 1rem;
}

.navbar img {
  height: 50px;
  width: auto;
  justify-self: start;
}

.navbar ul {
  list-style: none;
  display: none;
  justify-self: end;
  grid-template-columns: repeat(5, 1fr);
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;

  a {
    font-family: Roboto;
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
    transition: color 0.3s ease-out;

    color: #0b132a;

    .svg-inline--fa {
      font-size: 25px;
    }
  }

  .cr-box {
    width: 30px;
    height: 30px;
  }
}

/* Media Queries */

/* Mobile Devices - Phones/Tablets */
@media (min-width: 576px) {
  .container {
    max-width: unset;
  }
}

@media(min-width: 768px) {
  .nav-wrapper {
    height: 102px;
  }

  .nav-wrapper::before {
    top: 70px;
  }

  .nav-wrapper >nav {
    height: 86px;
  }

  .navbar {
    img {
      height: 70px;
    }

    ul {
      display: grid;
    }
  }
}

@media(min-width: 1440px) {
  .navbar {
    padding-right: 8rem;
    padding-left: 8rem;
    min-width: 1200px;
  }
}


