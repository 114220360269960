$theme-colors: (
	"primary": #17a2b8,
	"dark": #222c47,
	"light": #efeff1,
	"danger": #f53838,
	"success": #28a745,
	"secondary": #7B808C,
);

@each $color, $value in $theme-colors {
	.btn-outline-#{$color} {
		border-width: 2px;
		padding: 0 1rem;
	}
}